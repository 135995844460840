@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --background: #ffffff;
  --foreground: #171717;
}

@media (prefers-color-scheme: dark) {
  :root {
    --background: #0a0a0a;
    --foreground: #ededed;
  }
}

@layer base {
  * {
    text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }
}


body {
  color: var(--foreground);
  background: var(--background);
}
:root {
  --font-pretendard: 'Pretendard Variable', sans-serif;
}

body {
  font-family: var(--font-pretendard);
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}


.card-image-size {
  aspect-ratio: 262 / 224;
}

.card-size {
  aspect-ratio: 294 / 390;
}

.home-logo-size {
  aspect-ratio: 82 / 107;
}

.progress-color-value::-webkit-progress-value {
  background-color: #FF334C;
}

/* Custom CSS */
.progress-color-value::-moz-progress-bar {
  background-color: #FF334C;
}

.progress-color-value {
  background-color: #F1F5F9;
}

.food-detail-card-size {
  aspect-ratio: 112 / 112;
}

.food-detail-card-image-size {
  aspect-ratio: 80 / 80;
}

.welcome-image-size {
  aspect-ratio: 288 / 288;
}

.no-choice-card {
  aspect-ratio: 172/234;
}

.random-card{
  aspect-ratio: 305/450;
}

.random-image {
  aspect-ratio: 240 / 240;
}

.hearts-size {
  aspect-ratio: 640/144;
}

.naver-map-container .map_copyright,
.naver-map-container .btn_maps,
.naver-map-container .map_logo_wrap {
  display: none !important;
}